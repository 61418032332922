'use client';
import { createContext, useRef, useState } from 'react';

import * as ga from '../lib/ga';

const PartnersContext = createContext();

const validPartnerFinderFilters = [
  'expertise',
  'isKnightsOnStaff',
  'partnerTypeAuthorizedEducationDelivery',
  'partnerTypeConsultingServices',
  'partnerTypeAuthorizedTechnicalSupport',
  'isPublic',
  'accreditations',
  'isClearedResources',
  'certifications',
  'regions',
  'countries',
  'languages',
  'services',
  'useCasesType',
  'verticals',
  'complexSearch',
  'starRating'
];

export function PartnersContextProvider({ children }) {
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageRR, setCurrentPageRR] = useState(0);
  const [currentPageRC, setCurrentPageRC] = useState(0);
  const [currentPageBC, setCurrentPageBC] = useState(0);
  const [currentPagePREV, setCurrentPagePREV] = useState(0);
  const [currentPageOB, setCurrentPageOB] = useState(0);
  const [currentPageKnight, setCurrentPageKnight] = useState(0);
  const [currentPageLogger, setCurrentPageLogger] = useState(0);
  const [currentForecastPageLogger, setCurrentForecastPageLogger] = useState(0);
  const [randomizedExpert, setRandomizedExpert] = useState(null);
  const [randomizedNoExpert, setRandomizedNoExpert] = useState(null);
  const [randomizedRelated, setRandomizedRelated] = useState(null);
  const [randomizedRelatedNoExpert, setRandomizedRelatedNoExpert] =
    useState(null);
  const [year, setYear] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [currentProdForecastPageLogger, setCurrentProdForecastPageLogger] =
    useState(0);
  const [currentPageSub, setCurrentPageSub] = useState(0);
  const [state, setState] = useState({ status: '', invalidFields: [] });
  const [currentPageFiltering, setCurrentPageFiltering] = useState(0);
  const [relatedPageFiltering, setRelatedPageFiltering] = useState(0);
  const [currentPageForecast, setCurrentPageForecast] = useState(0);
  const [currentPageCompanyLog, setCurrentPageCompanyLog] = useState(0);
  const [currentPageServices, setCurrentPageServices] = useState(0);
  const [currentPageCertifications, setCurrentPageCertifications] = useState(0);
  const [currentPageTags, setCurrentPageTags] = useState(0);
  const [currentPageSurvey, setCurrentPageSurvey] = useState(0);
  const [currentPageRoles, setCurrentPageRoles] = useState(0);
  const [pipelinesPagination, setPipelinesPagination] = useState({
    valid: 0,
    invalid: 0
  });
  const [renewalsPagination, setRenewalsPagination] = useState({
    valid: 0,
    invalid: 0
  });
  const [sortByValidProdForecasts, setSortByValidProdForecasts] = useState('');
  const [sortByInvalidProdForecasts, setSortByInvalidProdForecasts] =
    useState('');

  const [language, setLanguage] = useState('en-us');

  const [f, setF] = useState('nan');
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [loadingURL, setLoadingURL] = useState(true);
  const [isLoadingMenu, setIsLoadingMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [whereToComeBack, setWhereToComeBack] = useState({});

  const [onEditor, setOnEditor] = useState(false);

  const [currentPageBPosts, setCurrentPageBPosts] = useState(0);
  const [currentPageFeaturedKnights, setCurrentPageFeaturedKnights] =
    useState(0);
  const [sortByFeaturedKnights, setSortByFeaturedKnights] =
    useState('featuredAt-desc');

  //Used in localStorage with the key 'isFirstWebsiteVisit' to only show the search tooltip on the first visit
  const [isFirstWebsiteVisit, setFirstWebsiteVisit] = useState();

  const printPartnerPublicPageRef = useRef(); //use to print the partner public page and then send it via email

  // Initial state of the component, everything is closed and unchecked
  const useCasesInitialState = {
    useCases: {
      isClosed: true,
      isChecked: []
    }
  };

  const [finishingPartnerOnboarding, setFinishingPartnerOnboarding] = useState(
    {}
  );

  const verticalsInitialState = {
    verticals: {
      isClosed: true,
      isChecked: []
    }
  };

  const [useCasesFilterState, setUseCasesFilterState] =
    useState(useCasesInitialState);

  const [verticalsFilterState, setVerticalsFilterState] = useState(
    verticalsInitialState
  );

  const [fieldsValidity, setFieldsValidity] = useState([]);
  const [requiredFields, setRequiredFields] = useState([]);

  const clickOnFilter = (filter) => {
    ga.event({
      action: 'search',
      params: {
        search_term: 'filter (' + filter + ')'
      }
    });
  };

  function updateFilters(prop, value) {
    setFilters((prevFilters) => {
      let objCopy = { ...prevFilters };
      if (
        [
          'isPublic',
          'isClearedResources',
          'isKnightsOnStaff',
          'partners_dashboard_hasKnights',
          'partners_dashboard_isClearedResources',
          'partners_dashboard_isPublic',
          'partnerTypeConsultingServices',
          'partnerTypeAuthorizedEducationDelivery',
          'partnerTypeAuthorizedTechnicalSupport',
          'connections_publicSector',
          'connections_hasClearedResources'
        ].includes(prop)
      ) {
        if (prop === 'connections_publicSector' && prop in objCopy) {
          delete objCopy['connections_hasClearedResources'];
        }
        if (prop === 'partners_dashboard_isPublic' && prop in objCopy) {
          delete objCopy['partners_dashboard_isClearedResources'];
        }
        prop in objCopy ? delete objCopy[prop] : (objCopy[prop] = value);
      } else if (
        ['certifications', 'partners_dashboard_certifications'].includes(prop)
      ) {
        if (value.length === 0) {
          delete objCopy[prop];
        } else {
          objCopy[prop] = value;
        }
      } else if (
        [
          'expertise',
          'partners_dashboard_products',
          'partnerTypeConsultingServices',
          'partnerTypeAuthorizedEducationDelivery',
          'partnerTypeAuthorizedTechnicalSupport',
          'accreditations',
          'partners_dashboard_accreditations'
        ].includes(prop)
      ) {
        if (prop in objCopy) {
          if (objCopy[prop].includes(value)) {
            objCopy[prop] = objCopy[prop].filter((e) => e !== value);
            if (objCopy[prop].length === 0) delete objCopy[prop];
          } else {
            objCopy[prop].push(value);
          }
        } else {
          objCopy[prop] = [value];
        }
      } else if (
        [
          'prodForecasts_oppStage',
          'prodForecasts_oppHealth',
          'prodForecasts_owner',
          'prodForecasts_prodFamily',
          'prodForecasts_prodSubFamily',
          'prodForecasts_renewalStatus',
          'prodForecasts_leadSource',
          'connections_country',
          'forecasts_quarter',
          'connections_quarter',
          'regions',
          'countries',
          'languages',
          'services',
          'useCasesType',
          'verticals',
          'partners_dashboard_locations',
          'partners_dashboard_languages',
          'partners_dashboard_services',
          'partners_dashboard_useCasesType',
          'partners_dashboard_verticals',
          'partners_dashboard_divisions',
          'searchPartnerName',
          'complexSearch',
          'searchBroadcomContactEmail',
          'searchPartnerContactEmail',
          'customerCompanyName',
          'searchCustomerName'
        ].includes(prop) ||
        prop.includes('statusServiceOpportunity') ||
        prop.includes('quarter')
      ) {
        objCopy[prop] = value;
        if (objCopy[prop].length === 0) {
          delete objCopy[prop];
        }
      } else if (prop.includes('per_page')) {
        objCopy[prop] = value;
      } else {
        value ? (objCopy[prop] = value) : delete objCopy[prop];
      }

      return objCopy;
    });
  }

  function clearFilters() {
    setFilters({});
    setUseCasesFilterState(useCasesInitialState);
    setVerticalsFilterState(verticalsInitialState);
  }

  function clearFiltersExceptSearch() {
    if (filters.complexSearch)
      setFilters({ complexSearch: filters.complexSearch });
    else setFilters({});
    setUseCasesFilterState(useCasesInitialState);
    setVerticalsFilterState(verticalsInitialState);
  }

  function clearFinderToolRandomization() {
    setRandomizedExpert(null);
    setRandomizedNoExpert(null);
    setRandomizedRelated(null);
    setRandomizedRelatedNoExpert(null);
  }

  function clearFinderToolPagination() {
    setCurrentPageFiltering(0);
    setRelatedPageFiltering(0);
  }

  const totalFilters = Object.entries(filters)
    ?.filter((el) => validPartnerFinderFilters.includes(el[0]))
    ?.reduce((total, filter) => {
      return total + (Array.isArray(filter[1]) ? filter[1].length : 1);
    }, 0);

  const [checkedFilter, setCheckedFilter] = useState({ id: 0, name: 'None' });

  const visibleTotalFilters = totalFilters;

  const context = {
    filters,
    totalFilters,
    visibleTotalFilters,
    updateFilters,
    setFilters,
    clickOnFilter,
    clearFilters,
    clearFiltersExceptSearch,
    currentPage,
    setCurrentPage,
    currentPageRR,
    setCurrentPageRR,
    currentPageRC,
    setCurrentPageRC,
    currentPageBC,
    setCurrentPageBC,
    currentPagePREV,
    setCurrentPagePREV,
    currentPageOB,
    setCurrentPageOB,
    currentPageKnight,
    setCurrentPageKnight,
    currentPageLogger,
    setCurrentPageLogger,
    currentPageForecast,
    setCurrentPageForecast,
    currentForecastPageLogger,
    setCurrentForecastPageLogger,
    currentProdForecastPageLogger,
    setCurrentProdForecastPageLogger,
    currentPageCompanyLog,
    setCurrentPageCompanyLog,
    useCasesFilterState,
    setUseCasesFilterState,
    verticalsFilterState,
    setVerticalsFilterState,
    currentPageSub,
    setCurrentPageSub,
    currentPageFiltering,
    setCurrentPageFiltering,
    currentPageServices,
    setCurrentPageServices,
    currentPageTags,
    setCurrentPageTags,
    currentPageSurvey,
    setCurrentPageSurvey,
    currentPageRoles,
    setCurrentPageRoles,
    currentPageCertifications,
    setCurrentPageCertifications,
    currentPageBPosts,
    setCurrentPageBPosts,
    currentPageFeaturedKnights,
    setCurrentPageFeaturedKnights,
    sortByFeaturedKnights,
    setSortByFeaturedKnights,
    f,
    setF,
    loadingInfo,
    setLoadingInfo,
    state,
    setState,
    isFirstWebsiteVisit,
    setFirstWebsiteVisit,
    relatedPageFiltering,
    setRelatedPageFiltering,
    isLoadingMenu,
    setIsLoadingMenu,
    language,
    setLanguage,
    isOpen,
    setIsOpen,
    whereToComeBack,
    setWhereToComeBack,
    loadingURL,
    setLoadingURL,
    validPartnerFinderFilters,
    randomizedExpert,
    setRandomizedExpert,
    randomizedNoExpert,
    setRandomizedNoExpert,
    randomizedRelated,
    setRandomizedRelated,
    randomizedRelatedNoExpert,
    setRandomizedRelatedNoExpert,
    year,
    setYear,
    unsavedChanges,
    setUnsavedChanges,
    finishingPartnerOnboarding,
    setFinishingPartnerOnboarding,
    printPartnerPublicPageRef,
    sortByValidProdForecasts,
    setSortByValidProdForecasts,
    sortByInvalidProdForecasts,
    setSortByInvalidProdForecasts,
    onEditor,
    setOnEditor,
    pipelinesPagination,
    setPipelinesPagination,
    renewalsPagination,
    setRenewalsPagination,
    clearFinderToolRandomization,
    clearFinderToolPagination,
    fieldsValidity,
    setFieldsValidity,
    requiredFields,
    setRequiredFields,
    checkedFilter,
    setCheckedFilter
  };

  return (
    <PartnersContext.Provider value={context}>
      {children}
    </PartnersContext.Provider>
  );
}

export default PartnersContext;
